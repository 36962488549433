import Swiper from "swiper/swiper-bundle";

const swiper = new Swiper(".swiper-gallery", {
  grabCursor: true,
  spaceBetween: 4,
  slidesPerView: 1,
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
  loop: true,
  autoplay: {
    delay: 4000,
  },
});

const swiperThumbs = document.querySelectorAll("#swiper-thumbs img");

function clearActiveSlides() {
  swiperThumbs.forEach((thumb) => thumb.classList.remove("opacity-50"));
}

swiperThumbs.forEach((thumb) => {
  thumb.addEventListener("click", (e) => {
    clearActiveSlides();
    swiper.slideTo(e.target.dataset.imgId);
    e.target.classList.add("opacity-50");
  });
});

swiper.on("slideChange", (slide) => {
  clearActiveSlides();
  swiperThumbs[slide.activeIndex - 1].classList.add("opacity-50");
});

// TODO: Rewrite to onClick function
document.querySelector("#toggleGraph").addEventListener("click", () => {
  document.getElementById("graph").classList.toggle("hidden");
});
